/* eslint-disable no-unused-vars */
import router from "@/router/index.js";
import store from '@/state/store';
import axios from "axios";
import swal from 'sweetalert';
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

export default {
    namespaced: true,
    state: {
        authRequestBody: {
            username: "",
            password: ""
        },
        token: null,
        userId: " ",
        userName: " ",
        userRole: null,
    },


    mutations: {
        retrieveToken(state, token) {
            state.token = token
        },
        destroyToken(state) {
            state.token = null
        },
        setUser(state, value) {
            state.user = JSON.parse(JSON.stringify(value));
        },
        setUserId(state, id) {
            state.userId = id
        },
        setUserName(state, name) {
            state.userName = name
        },
        setUserRole(state, role) {
            state.userRole = role;
        },
    },


    actions: {
        authenticate: ({ commit }, authRequestBody) => {
            return new Promise((resolve, reject) => {

                axios.post('/auth/authenticate', authRequestBody)
                    .then(response => {
                        const token = response.data.jwt
                        localStorage.setItem('jwt', token)
                        commit('retrieveToken', token)
                        commit('setUserName', authRequestBody.username)
                        commit("setUserRole", response.data.roles[0].authority);
                        resolve(true);
                        router.push("/");
                    })
                    .catch(error => {
                        swal("Incorrect credentials, please try again!")
                        reject(error)
                    })



                // const config = {
                //     headers: {
                //         'Content-Type': 'application/x-www-form-urlencoded'
                //     }
                // }

                // const params = new URLSearchParams()
                // params.append('username', credentials.username)
                // params.append('password', credentials.password)
                // params.append('grant_type', 'password')
                // params.append('client_id', 'quarkus-service')

                // axios.post('http://localhost:8080/auth/realms/quarkus/protocol/openid-connect/token', params, config)
                //     .then(response => {
                //         const token = response.data.access_token
                //         localStorage.setItem('jwt', token)
                //         commit('retrieveToken', token)
                //         commit('setUserName', credentials.username)
                //         resolve(true);
                //         router.push("/");
                //     })
                //     .catch(error => {
                //         swal("Incorrect credentials, please try again!")
                //         reject(error)
                //     })
            })
        },

        retrieveUser: ({ commit, state }) => {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios.get('/getUser/{userName}?userName=' + state.userName)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            resolve(true);
                            commit('setUser', data)
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            })

        },

        retrieveUserId: ({ commit, state }) => {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios.get('/getUser/{userName}?userName=' + state.userName)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            resolve(true);
                            commit('setUserId', data.userId)
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            })

        },

        destroyToken: (context) => {
            return new Promise((resolve) => {
                localStorage.clear();
                delete axios.defaults.headers.common['Authorization']
                context.commit('destroyToken')
                sessionStorage.clear();
                resolve(true);
            })
        },
    },

    getters: {
        loggedIn(state) {
            return state.token !== null;
        },
        getUserId(state) {
            return state.userId
        },
        getUserName(state) {
            return state.userName
        },
        getToken(state) {
            return state.token
        }
    },

}