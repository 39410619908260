  <template>  
  <div class="ma-5">
    <NavBar></NavBar>  
    <v-row>
      <v-col cols="12">
        <v-row align="center" justify="center" class="grey lighten-5" style="height: 300px;">
          <v-col cols="4">
            <h2>Welcome Georgia</h2>
            <v-img :src="logoterra"></v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
export default {
  components: { NavBar },
  name: "crm",
  data() {
    return {
      logoterra: require("@/assets/logoterra.jpg"),
    };
  },
};
</script>