<template>
  <v-container>
    <v-row>
      <NavBar></NavBar>    
    </v-row>    
      <v-form ref="form" v-model="valid">
        <v-row justify="center" align="center">
          <v-col cols="6" sm="6" md="6">
            <v-text-field
              dense
              :rules="myRules"
              required
              v-model="selectedCustomer.firstName"
              name="selectedStudentFirstName"
              label="Όνομα"
              filled
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" sm="6" md="6">
            <v-text-field
              dense
              :rules="myRules"
              required
              filled
              v-model="selectedCustomer.lastName"
              name="selectedStudentLastName"
              label="Επίθετο"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <v-col cols="6" sm="6" md="6">
            <v-text-field
              dense
              :rules="myRules"              
              filled
              v-model="selectedCustomer.email"
              name="selectedStudentEmail"
              label="Email"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" sm="6" md="6">
            <v-text-field
              dense
              filled
              v-model="selectedCustomer.fatherName"
              name="selectedStudentFatherName"
              label="Πατρώνυμο"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <v-col cols="6" sm="6" md="6">
            <v-text-field
              dense
              filled
              v-model="selectedCustomer.adt"
              name="selectedStudentAdt"
              label="Α.Δ.Τ"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" sm="6" md="6">
            <v-text-field
              dense
              filled
              v-model="selectedCustomer.afm"
              name="selectedStudentAfm"
              label="Α.Φ.Μ"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <v-col cols="4" sm="4" md="4">
            <v-text-field
              dense
              filled
              v-model="selectedCustomer.birthPlace"
              name="selectedStudentBirthPlace"
              label="Τόπος Γέννησης"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4" sm="4" md="4">
            <!--  DATE -->
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="selectedCustomer.birthDate"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  filled
                  v-bind="attrs"
                  v-on="on"
                  v-model="selectedCustomer.birthDate"
                  label="Ημ/νία Γέννησης"
                >
                </v-text-field>
              </template>
              <v-date-picker no-title scrollable v-model="formatedDate">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(selectedCustomer.birthDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>

            <!--  DATE -->
          </v-col>
          <v-col cols="4" sm="4" md="4">
            <v-text-field
              dense
              filled
              v-model="selectedCustomer.nationality"
              name="selectedStudentNationality"
              label="Υπηκοότητα"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row justify="center" align="center">
          <v-col cols="4" sm="4" md="4">
            <v-text-field
              dense
              filled
              v-model="selectedCustomer.homePhone"
              name="selectedStudentHomePhone"
              label="Σταθερό τηλέφωνο"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4" sm="4" md="4">
            <v-text-field
              dense
              filled
              v-model="selectedCustomer.mobilePhone"
              name="selectedStudentMobilePhone"
              label="Κινητό τηλέφωνο"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4" sm="4" md="4">
            <v-select
              dense
              filled
              :items="gender"
              v-model="selectedCustomer.gender"
              label="Φύλο"
              :menu-props="{ top: false, offsetY: true }"
            ></v-select>
          </v-col>
        </v-row>

        <v-row justify="center" align="center">
          <v-col cols="4" sm="4" md="4">
            <v-select
              :items="this.getAllCountries"
              dense
              filled
              item-text="name"
              item-value="id"
              v-model="selectedCustomer.country"
              name="selectedStudentCountry"
              label="Χώρα"
              return-object
            >
            </v-select>
          </v-col>
          <v-col cols="4" sm="4" md="4">
            <v-text-field
              dense
              filled
              v-model="selectedCustomer.department"
              name="selectedStudentDepartment"
              label="Νομός"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4" sm="4" md="4">
            <v-text-field
              dense
              filled
              v-model="selectedCustomer.city"
              name="selectedStudentCity"
              label="Πόλη"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row justify="center" align="center">
          <v-col cols="4" sm="4" md="4">
            <v-text-field
              dense
              filled
              v-model="selectedCustomer.postalCode"
              name="selectedStudentPostalCode"
              label="Ταχυδρομικος Κώδικας"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4" sm="4" md="4">
            <v-text-field
              dense
              filled
              v-model="selectedCustomer.addressStreet"
              name="selectedStudentAddressStreet"
              label="Οδός"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4" sm="4" md="4">
            <v-text-field
              dense
              filled
              v-model="selectedCustomer.addressNumber"
              name="selectedStudentAddressNumber"
              type="number"
              label="Αριθμός"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row justify="center" align="center">
          <v-col cols="12" sm="12" md="12">
            <v-textarea
              rows="5"
              filled
              v-model="selectedCustomer.notes"
              :counter="150"
              label="Σημειώσεις"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <!-- <v-col cols="4"></v-col> -->

          <v-btn class="ma-2" @click="$router.go(-1)" color="secondary"
            >ΠΙΣΩ</v-btn
          >
          <v-btn
            v-if="this.$route.params.customerId != 0"
            @click="updateCurrentCustomer()"
            color="warning"
            >ΑΠΟΘΗΚΕΥΣΗ</v-btn
          >
          <v-btn
            v-if="this.$route.params.customerId == 0"
            :disabled="!valid"
            @click="createNewCustomer()"
            color="warning"
            >ΔΗΜΙΟΥΡΓΙΑ</v-btn
          >
          <!-- <v-col cols="4"></v-col> -->
        </v-row>
      </v-form>    
    </v-container>  
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NavBar from "@/components/NavBar.vue";

export default {  
  components: { NavBar },

  data: () => ({
    menu: false,
    selectedCustomer: {},
    valid: false,
    myRules: [(v) => !!v || "Το πεδίο είναι υποχρεωτικό"],
    gender: ["Άνδρας", "Γυναίκα"],    
  }),

  mounted() {              
    if (this.$route.params.customerId != 0) {      
      this.fetchCustomeById(this.$route.params.customerId);
    }    
  },

  methods: {
    ...mapActions("customers", ["fetchCustomerByIdAction"]),
    ...mapActions("customers", ["updateCustomerAction"]),
    ...mapActions("customers", ["createCustomer"]),
    ...mapActions("majors", ["fetchAllMajors"]),
    ...mapActions("country", ["fetchAllCountries"]),
    
    async fetchCustomeById(customerId) {
      await this.fetchCustomerByIdAction(customerId);
      this.selectedCustomer = this.getSelectedCustomer;
    },

    async updateCurrentCustomer() {
      await this.updateCustomerAction(this.selectedCustomer);
      this.$router.push("/customers");
    },
    
    async createNewCustomer() {
      await this.createCustomer(this.selectedCustomer);
      this.$router.push("/customers");
    },
    
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
  },

  computed: {
    ...mapGetters("customers", ["getSelectedCustomer"]),    
    ...mapGetters("countries", ["getAllCountries"]),
    formatedDate: {
      get: function () {
        return null;
      },
      set: function (val) {
        return (this.selectedCustomer.birthDate = this.formatDate(val));
      },
    },
  },
};
</script>


<style scoped>
</style>
