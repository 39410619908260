<template>
  <v-container>
    <v-row>
      <NavBar></NavBar>
    </v-row>
    <v-form ref="form" v-model="valid">
      <v-row justify="center" align="center">
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            :disabled="this.$route.params.tripId != 0"
            dense
            :rules="myRules"
            required
            v-model="selectedTrip.tripName"
            label="Όνομασία Εκδρομής"
            filled
          >
          </v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-menu
            :disabled="this.$route.params.tripId != 0"
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="selectedTrip.date"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                filled
                v-bind="attrs"
                v-on="on"
                v-model="selectedTrip.date"
                label="Ημερομηνία Εκδρομής"
              >
              </v-text-field>
            </template>
            <v-date-picker no-title scrollable v-model="formatedDate">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(selectedTrip.date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row justify="center" align="center">
        <v-col cols="6" sm="6" md="6">
          <v-select
            dense
            filled
            :items="tripStatusOptions"
            v-model="selectedTrip.active"
            label="Να φαίνεται στην λίστα?"
            :menu-props="{ top: false, offsetY: true }"
          ></v-select>
        </v-col>
      </v-row>

      <v-row justify="center" align="center">
        <!-- <v-col cols="4"></v-col> -->

        <v-btn class="ma-2" @click="$router.go(-1)" color="secondary"
          >ΠΙΣΩ</v-btn
        >
        <v-btn
          v-if="this.$route.params.tripId != 0"
          @click="updateCurrentTrip()"
          color="warning"
          >ΑΠΟΘΗΚΕΥΣΗ</v-btn
        >
        <v-btn
          v-if="this.$route.params.tripId == 0"
          :disabled="!valid"
          @click="createNewTrip()"
          color="warning"
          >ΔΗΜΙΟΥΡΓΙΑ</v-btn
        >
        <!-- <v-col cols="4"></v-col> -->
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NavBar from "@/components/NavBar.vue";

export default {
  components: { NavBar },

  data: () => ({
    menu: false,
    selectedTrip: {},
    valid: false,
    myRules: [(v) => !!v || "Το πεδίο είναι υποχρεωτικό"],
    tripStatusOptions: [
      { text: "ΝΑΙ", value: true },
      { text: "ΟΧΙ", value: false },
    ],
  }),

  mounted() {
    if (this.$route.params.tripId != 0) {
      this.fetchTripById(this.$route.params.tripId);
    }
  },

  methods: {
    ...mapActions("trips", [
      "fetchTripByIdAction",
      "updateTripAction",
      "createTrip",
    ]),

    async fetchTripById(tripId) {
      await this.fetchTripByIdAction(tripId);
      this.selectedTrip = this.getSelectedTrip;
    },

    async updateCurrentTrip() {
      await this.updateTripAction(this.selectedTrip);
      this.$router.push("/trips");
    },

    async createNewTrip() {
      await this.createTrip(this.selectedTrip);
      this.$router.push("/trips");
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${year}-${month}-${day}`;
    },
  },

  computed: {
    ...mapGetters("trips", ["getSelectedTrip"]),

    formatedDate: {
      get: function () {
        return null;
      },
      set: function (val) {
        return (this.selectedTrip.date = this.formatDate(val));
      },
    },
  },
};
</script>


<style scoped>
</style>
