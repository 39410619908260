<template>
  <v-container>
    <v-row>
      <NavBar></NavBar>
    </v-row>
    <v-row>
      <v-col cols="8"> </v-col>
      <v-col cols="2"></v-col>
      <v-col cols="2">
        <v-btn @click="viewItem(null)" color="success"
          >ΕΙΣΑΓΩΓΗ ΝΕΑΣ ΕΚΔΡΟΜΗΣ</v-btn
        >
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col>
        <v-data-table
          :headers="tripHeaders"
          :items="this.getAllTrips"
          :loading="loadTrips"
          :items-per-page="-1"
          class="elevation-1"
        >
          <template v-slot:[`item.view`]="{ item }">
            <v-icon small class="mr-2" @click="viewItem(item)">
              mdi-eye
            </v-icon>
          </template>
          <template v-slot:[`item.active`]="{ item }">
            <span v-if="item.active==true">ΝΑΙ</span>
            <span v-else>OXI</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { NavBar },
  name: "trips",
  data: () => ({
    loadTrips: true,
    search: "",
    tripHeaders: [
      { text: "A/A", value: "tripId" },
      { text: "Ονομασία Εκδρομής", value: "tripName" },
      { text: "Ημερομηνία", value: "date" },
      { text: "Να φαίνεται?", value: "active" },
      {
        text: "Προβολή",
        value: "view",
        align: "center",
        sortable: false,
        class: "subtitle-2 font-weight-bold",
      },
    ],
    trips: [],
  }),

  mounted() {
    this.fetchAllTrips();
  },

  computed: {
    ...mapGetters("trips", ["getAllTrips"]),
  },

  methods: {
    ...mapActions("trips", ["fetchAllTripsAction"]),

    async fetchAllTrips() {
      await this.fetchAllTripsAction();
      this.getAllTrips;
      this.loadTrips = false;
    },

    viewItem(item) {
      if (item == null) {
        this.$router.push({
          name: "Trip",
          params: {
            tripId: 0,
          },
        });
      } else {
        this.$router.push({
          name: "Trip",
          params: {
            tripId: item.tripId,
          },
        });
      }
    },
  },
};
</script>


<style type = "text/css">
headline {
  padding: 10px;
}
input.styled {
  font: bold 15px;
  margin: "1px";
}
label {
  font-weight: bold;
}
h3 {
  display: inline-block;
}
</style>