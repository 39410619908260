import axios from 'axios';
import store from '@/state/store.js'

export default {
    namespaced: true,
    state: {
        allCountries: []
    },

    mutations: {
        setCountries(state, allCountries) {
            state.allCountries = allCountries;
        }
    },

    actions: {
        fetchAllCountries: ({ commit }) => {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios
                    .get("/countries")
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("setCountries", data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
    },

    getters: {
        getAllCountries: state => state.allCountries
    },
}