import axios from 'axios';
import store from '@/state/store.js'

export default {
    namespaced: true,
    state: {
        newCustomer: {},
        selectedCustomer: {},
        allCustomers: []
    },

    mutations: {
        setCustomers(state, allCustomers) {
            state.allCustomers = allCustomers;
        },
        setSelectedCustomer(state, selectedCustomer) {
            state.selectedCustomer = selectedCustomer;
        },
        setNewCustomer(state, newCustomerDetails) {
            state.newCustomer = newCustomerDetails;
        },
        updateCustomer(state, updatedCustomerData) {
            state.selectedCustomer = updatedCustomerData;
        }
    },

    actions: {

        fetchAllCustomersAction: ({ commit }) => {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios
                    .get("/customers")
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("setCustomers", data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        fetchCustomerByIdAction: ({ commit }, customerId) => {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios
                    .get("/customers/" + customerId)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("setSelectedCustomer", data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        searchCustomersByLastOrFirstName: ({ commit }, searchName) => {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios
                    .get("/customers/search/" + searchName)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("setCustomers", data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },


        createCustomer({ commit }, newCustomerDetails) {
            return new Promise((resolve, reject) => {
                // axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios
                    .post('/customers', newCustomerDetails)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit('setNewCustomer', data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },


        updateCustomerAction({ commit }, customerDetails) {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios
                    .post('/customers', customerDetails)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit('updateCustomer', data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        deleteCustomerAction({ commit }, customerToBeDeleted) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(
                        '/customers', {
                            headers: {
                                Authorization: 'Bearer ' + store.state.user.token
                            },
                            data: customerToBeDeleted
                        }
                    )
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit('setCustomers', data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },


    },

    getters: {
        getAllCustomers: state => state.allCustomers,
        getSelectedCustomer: state => state.selectedCustomer
    },
}